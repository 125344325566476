import {AppBar, Tab, Tabs} from "@material-ui/core";
import React, {useEffect} from "react";
import SwipeableViews, {OnSwitchingCallbackTypeDescriptor} from 'react-swipeable-views';
import "../sass/verein.sass";
import {useTheme} from '@material-ui/core/styles';
import PDFViewer from "../components/pdfViewer";
import ImageViewer from "../components/imageviewer";
import {verstorbeneMitglieder, vorstand} from "stammdaten";
import {TabPanel} from "../App";
import {withRouter} from "react-router-dom";
import {useSelector} from "react-redux";
import {AppState} from "../app/appState";
import * as config from "../app/config";


const vorstand2020 = [
    {
        name: "Der aktuelle Vorstand",
        description: "(v.l.n.r.) Bürgermeister Christian Graf, Stephan Walter, Thomas Stadlmeier, Stefan Spangler, Dietmar Walter, Josef Eichenseher, Thomas Mulzer, Lukas Ring, Rene Drescher-Hackel, Andreas Graf",
        src: "vorstand/2020.jpg"
    }
]
const vorstand2022 = [
    {
        name: "Der aktuelle Vorstand",
        description: "(v.l.n.r.) Dietmar Walter, Andreas Graf, Stephan Walter, Thomas Stadlmeier, Thomas Mulzer, Lukas Ring, Georg Spangler",
        src: "vorstand/2024.jpg"
    }
]

const vorstand2024 = [
    {
        name: "Der aktuelle Vorstand",
        description: "(v.l.n.r.) Stephan Walter, Matthias Hofmann, Thomas Mulzer, Thomas Stadlmeier, Corina Hofmann, Lukas Ring,Andreas Graf, Georg Spangler, Rene Rendant",
        src: "vorstand/2024.jpg"
    }
]

function ErsterSchriftfuehrer() {
    return (
        <div>
            <ul style={{listStyle: "none"}}>
                <li>{vorstand.ErsterSchriftfuehrer.Name}</li>
                <li>{vorstand.ErsterSchriftfuehrer.Strasse}</li>
                <li>{vorstand.ErsterSchriftfuehrer.Ort}</li>
                <li>{vorstand.ErsterSchriftfuehrer.Mail}</li>
            </ul>
        </div>
    )
}


function Verein() {


    const [value, setValue] = React.useState(0);

    useEffect(() => {
        let bg = document.getElementById("bg");
        if (bg !== undefined && bg !== null)
            bg.className = 'hohenfels';
    }, []);

    const stateData = useSelector((state: AppState) => state);
    // const appData = stateData.appData;
    const userData = stateData.userData;
    // const user = userData.currentUser;

    const handleChange = (event: React.ChangeEvent<{}>, newValue: number) => {
        setValue(newValue);
        let bg = document.getElementById("bg");
        if (bg !== undefined && bg !== null)
            switch (newValue) {
                case 1:
                    bg.className = 'hohenfels';
                    break;
                case 2:
                    bg.className = 'hohenfels';
                    break;
                case 3:
                    bg.className = 'gedenken';
                    break;
                case 4:
                    bg.className = 'hohenfels';
                    break;
                default:
                    bg.className = 'hohenfels';
                    break;
            }
    };

    const swipeableViewSwitching = (index: number, type: OnSwitchingCallbackTypeDescriptor) => {
        //  console.log("swipeableViewSwitching -> aktuell: " + value + " index: " + index + " type: " + type);
        if (type === "end") {
            setValue(index);
        }
    }

    const theme = useTheme();

    return (


        <div className="verein">
            <AppBar color="transparent" className="appBarSubMenu">
                <Tabs
                    variant="scrollable"
                    className="menuTabs"
                    value={value}
                    indicatorColor="primary"
                    onChange={handleChange}
                >
                    <Tab id="verein" label="Der Verein"/>
                    <Tab id="vorstand" label="Vorstand"/>
                    <Tab id="mitglieder" label="Mitglieder"/>
                    <Tab id="gedenken" label="Im Gedenken"/>
                    <Tab id="satzung" label="Satzung"/>
                </Tabs>
            </AppBar>
            <SwipeableViews
                axis={theme.direction === 'rtl' ? 'x-reverse' : 'x'}
                index={value}
                enableMouseEvents={true}
                onSwitching={swipeableViewSwitching}
            >
                { /* Vereinschronik */}
                <TabPanel value={value} index={0} dir={theme.direction}>
                    <article>
                        <h3>Der Verein und seine Geschichte</h3>

                        <p>12.04.2002 - Aus einem FC Bayern Stammtisch heraus ist der FC Bayern-Fanclub Forellenbachtal
                            enstanden. Erster Vorsitzender wurde Albert Vogl, der dem Fanclub bis heute treu geblieben
                            ist. Was sich die j&uuml;ngeren Fans heute kaum vorstellen k&ouml;nnen, war f&uuml;r die
                            Gr&uuml;ndung des
                            Fanclubs 2002 nur zweitrangig - der FC Bayern ist einmal nicht Deutscher
                            Fu&szlig;ballmeister
                            geworden. Der durch Hohenfels f&uuml;hrende Forellenbach war zugleich auch ideal f&uuml;r
                            die
                            Namensgebung des Fanclubs. </p>
                        <div style={{height: '410px'}}>
                            <ImageViewer autoPlay={false} indicators={true} items={[
                                {
                                    name: "",
                                    description: "Quelle: Mittelbayrische Zeitung 15.04.2002 / Archiv v. Albert Vogl",
                                    src: "verein/presse10001.jpg"
                                },
                                {
                                    name: "Gründung des FC Bayern Fanclub Forellenbachtal",
                                    description: "",
                                    src: "verein/Gruendung6.jpg"
                                },
                                {
                                    name: "Gründung des FC Bayern Fanclub Forellenbachtal",
                                    description: "",
                                    src: "verein/Gruendung3.jpg"
                                }
                            ]} untertitel="8px" maxWidth={400}/></div>
                        <p>Der erste Vorstand, der mit der Gr&uuml;ndung des Fanclubs seine Arbeit aufnahm:</p>
                        <ul className="list">
                            <li>Albert Vogl (1. Vorstand)</li>
                            <li>Mario Haneder (2. Vorstand)</li>
                            <li>Stephan Walter (3. Vorstand)</li>
                            <li>Gerhard Fischer (1. Schriftf&uuml;hrer)</li>
                            <li>Andreas Graf (2. Schriftf&uuml;hrer)</li>
                            <li>Peter Reindl (1. Kassierer)</li>
                            <li>Harald La&szlig;leben (2. Kassierer)</li>
                            <li>Agathe S&ouml;llner (Kassenpr&uuml;fer)</li>
                            <li>Irene Walter (Kassenpr&uuml;fer)</li>
                            <li>Helmut Hofmann (Beirat)</li>
                            <li>Heinz S&ouml;llner (Beirat)</li>
                            <li>J&uuml;rgen Lander (Beirat)</li>
                            <li>J&uuml;rgen Hiltl (Beirat)</li>
                            <li>Thomas Jungkunz (Beirat)</li>
                        </ul>
                        <p>Wichtig war bei der Gr&uuml;ndung schon, dass der Verein sich aktiv am gesellschaftlichen
                            Leben der Marktgemeinde Hohenfels beteiligen m&ouml;chte.</p>
                        <p>Zu den Gr&uuml;ndungsmitgliedern z&auml;hlten 74 Mitglieder.</p>

                        <p>Nur zwei Jahre sp&auml;ter konnte der Fanclub stolze 211 Mitglieder verzeichnen. Im
                            Rechenschaftsbericht 2004 konnte Albert Vogl durchweg positive Bilanz ziehen. So konnte
                            man auf mehrere gemeinsame Stadionbesuche, gut besuchte Grill- und Sommernachtsfeste, die
                            Faschingsb&auml;lle und die Weinfahrten zur&uuml;ck
                            blicken.
                        </p>
                        <ImageViewer untertitel="8px" maxWidth={400} autoPlay={false}
                                     items={[
                                         {
                                             name: "",
                                             description: "Quelle: Mittelbayrische Zeitung 2004 / Archiv v. Albert Vogl",
                                             src: "verein/presse20040001.jpg"
                                         }
                                     ]}
                        />
                        <p>Von seine Gr&uuml;ndung an bis 2020 konnte der Fanclub seinen Jahresbeitrag konstant niedrig
                            halten.</p>
                        <ul className="list">
                            <li>Kinder bis 6 Jahre: 1 &euro;</li>
                            <li>Kinder/Jugendliche (6-18 Jahre): 6 &euro;</li>
                            <li>Erwachsene: 12 &euro;</li>
                        </ul>

                        <p>2004 &uuml;bernahm J&uuml;rgen Lander das Amt des 1. Vortstandes, welche Albert Vogl aus
                            gesundheitlichen Gr&uuml;nden abgab.</p>

                        <p>2012 konnte der Fanclub Forellenbachtal sein 10-j&auml;hriges Bestehen feiern. Der Verein
                            z&auml;hlte
                            nunmehr satte 270 Mitglieder, darunter viele Kinder, Jugendliche und auch US-Amerikaner
                            (liegt doch der Truppen&uuml;bungsplatz der US-Armee in unmittelbarer Nachbarschaft zu
                            Hohenfels). </p>
                        <ImageViewer untertitel="8px" maxWidth={400} autoPlay={false} indicators={true}
                                     items={[
                                         {
                                             name: "",
                                             description: "Quelle: Mittelbayrische Zeitung vom 14.06.2012 / Archiv v. Albert Vogl",
                                             src: "verein/presse_2012_20001.jpg"
                                         },
                                         {
                                             name: "",
                                             description: "Quelle: Mittelbayrische Zeitung vom 22.06.2012 / Archiv v. Albert Vogl",
                                             src: "verein/presse2012_10001.jpg"
                                         }
                                     ]}
                        />
                        <p>Regelm&auml;&szlig;ige gemeinsame Fahrten zu ausgew&auml;hlten Heimspielen des FC Bayern
                            M&uuml;nchen und einmal
                            in der Saison auch ein Ausw&auml;rtsspiel standen in den vergangenen 10 Jahren
                            regelm&auml;&szlig;ig auf dem
                            Programm. Ebenso haben sich als feste Institution der j&auml;hrliche Faschingsball, das
                            Sommer-/Grillfest und die
                            Weihnachtsfeier etabliert.</p>
                        <p>Am Freitag, den 15. Juni startete der Fanclub seine Feier anl&auml;sslich seines
                            10-j&auml;hrigen
                            Jubil&auml;ums um 19:00 Uhr mit einer Plattenparty bei der die DJ's "Hubb", Martin und Simon
                            f&uuml;r
                            die passende Stimmung sorgten. Am darauf folgenden Samstag ging das Fest weiter -
                            mit dem Start eines gemeinsamen Fu&szlig;balltuniers, an dem 12 Mannschaften aus der
                            gesamten
                            Region teilnahmen. Abends spielte dann die Band "Standby".</p>
                        <p>Die Platzierungen aus dem Fu&szlig;balltunier:</p>
                        <ul className="list">
                            <li>FC Bayern Fanclub Hohenschambach</li>
                            <li>FC Bayern Fanclub Dietfurt</li>
                            <li><b><i>FC Bayern Fanclub Forellenbachtal Hohenfels</i></b></li>
                            <li>Mannschaft TSV hohenfels</li>
                            <li>Mannschaft des Burschenvereins Hohenfels</li>
                            <li>FC Bayern Fanclub Steinsberg</li>
                            <li>FC N&uuml;rnberg Fanclub Hohenfels</li>
                            <li>Mannschaft der "Kirwabuschen Oberpfraundorf"</li>
                            <li>FC Bayern Fanclub Kallm&uuml;nz</li>
                            <li>Mannschaft des Stammtischs Dinau</li>
                            <li>DamenMannschaft SpVgg Willenhofen-Herrnried</li>
                            <li>DamenMannschaft des SV Lupburg</li>
                        </ul>
                        <p>
                            Die Pokale wurden seinerzeit von ortsans&auml;ssigen Firmen gespendet
                        </p>
                        <p>Am 08.04.2017 feierte der Fanclub dann schon sein 15-j&auml;hriges Bestehen. </p>
                        <p>Niemand besseres konnte eine r&uuml;ckblickende Bilanz ziehen als der erste Vorstand aus dem
                            Gr&uuml;ndungsjahr 2002 - Albert Vogl.</p>
                        <i style={{fontSize: "smaller"}}>
                            <h4>15 Jahre FC-Bayern Fanclub Forellenbachtal Hohenfels</h4>
                            <b>1. Vereinsjahr</b>
                            <p>Blickt man auf 15 Jahre Bestehens des FC-Bayern Fanclub Forellenbachtal Hohenfels
                                zur&uuml;ck,
                                so ist
                                dies eine lange und doch auch wieder kurze Zeit. <br/>Es ist viel geschehen seit der
                                Gr&uuml;ndungsversammlung am 12. April 2002 im "Rathausst&uuml;berl". <br/>Die
                                markantesten und
                                wichtigsten
                                Ereignisse wollen wir heute noch einmal Revue passieren lassen. </p><p>Schon die
                            Gr&uuml;ndung kam
                            einem
                            Paukenschlag gleich. Nicht weniger als 80 Personen lie&szlig;en sich spontan als
                            Gr&uuml;ndungsmitglieder
                            aufnehmen, wahrlich eine imponierende Zahl. Es gibt landauf landab andere Fanclubs, die
                            schon
                            l&auml;nger bestehen und heute noch weniger Mitglieder aufweisen als wir am ersten Tag. Dies
                            ist ein
                            Beleg daf&uuml;r, dass die Attraktivit&auml;t des FC Bayern damals wie heute ungebrochen
                            ist. Die
                            erste
                            Vorstandschaft umfasste 14 Mitglieder, die in den Anfangsjahren viel zu tun hatten. Zum
                            Beispiel
                            hatten wir gleich dem FCN-Fanclub einen Besuch abzustatten weil dieser 2002 schon das
                            zehnj&auml;hrige Bestehen feiern konnte. Es war das erste &ouml;ffentliche Auftreten der
                            Vorstandschaft.
                            Als 100. Mitglied konnte bald Frau Lidwina Sch&ouml;ll aus H&ouml;rmannsdorf genannt werden.
                            Das
                            erste
                            gr&ouml;&szlig;ere Fest, das es zu organisieren galt, war das Sommernachtsfest, das damals
                            noch am
                            Pfarrer-Ertl-Platz vor dem Rathausst&uuml;berl stattfand. Es regnete am 13. Juli 2002 abends
                            in
                            Str&ouml;men, aber dennoch machten uns viele Leute ihre Aufwartung und lie&szlig;en sich von
                            uns
                            bewirten.
                            Mitten in der Nacht musste wieder aufger&auml;umt werden - am n&auml;chsten Tag war das
                            Pfarrfest.
                            Auch
                            eine geplante Fackelwanderung f&uuml;r unsere Kinder und Jugendlichen wurde in die Tat
                            umgesetzt.
                            Seit dem Gr&uuml;ndungsjahr organisierte der Fanclub allj&auml;hrlich auch ein Grillfest bei
                            der
                            Haarziegelh&uuml;tte. Das erste war am 7. September 2002 und kam so gut an, dass es seitdem
                            jedes
                            Jahr wiederholt wird. Wei&szlig; noch jemand, zu welcher Begegnung der Fanclub erstmals nach
                            M&uuml;nchen
                            aufgebrochen ist? Nun, es war FC Bayern gegen Arminia Bielefeld und das Spiel endete
                            erwartungsgem&auml;&szlig; mit einem Sieg des FC Bayern. Nicht vergessen werden darf auch
                            die
                            Weinfahrt
                            nach Obereisenheim bei Volkach am 19. Oktober 2002. F&uuml;r alle Mitgereisten war das Fest
                            in der
                            Winzerhalle von Obereisenheim ein unvergessliches Ereignis. Ebenso unsere erste
                            Weihnachtsfeier
                            am 7. Dezember 2002 im Keltensaal mit 180 Personen. Dann nahte schon der Faschingsball.
                            zu dem der Fanclub die Band "Muhagls" verpflichtet hatte und
                            zu dem 240 Personen erschienen. Das war am 15. Februar 2003. Und so konnte der
                            Vorsitzende bei
                            der ersten Generalversammlung am 11. April 2003 mit Stolz verk&uuml;nden, dass die
                            Mitgliederzahl
                            mittlerweile auf 180 Personen angewachsen war. Kein Wunder: Den Leuten wurde etwas
                            geboten und
                            der Mitgliedsbeitrag war damals wie heute niedrig.</p>

                            <b>2. Vereinsjahr</b>
                            <p>Das 2. Vereinsjahr begann im Mai 2003 mit einer Fahrt nach Berlin zur Begegnung Hertha
                                BSC gegen
                                Bayern M&uuml;nchen. Wir erlebten einen grandiosen Sieg unserer Mannschaft und konnten
                                danach
                                noch
                                zur Siegess&auml;ule und zum Brandenburger Tor fahren. Nun schon traditionell gab es
                                wieder
                                ein
                                Sommernachtsfest am Pfarrer-Ertl-Platz, ein Grillfest bei der Haarziegelh&uuml;tte, eine
                                Weinfahrt
                                nach Escherndorf bei W&uuml;rzburg, eine Weihnachtsfeier im Keltensaal und einen
                                Faschingsball mit
                                den "Original Lustigen Oberpf&auml;lzern". &uuml;berraschend konnten wir den Gastronomen
                                Josef
                                Gassner als
                                200. Mitglied in unseren Reihen begr&uuml;&szlig;en und unternahmen erstmals einen
                                Skiausflug vom
                                4. - 6.
                                Januar 2004 nach Tirol. Neu war auch, dass wir uns aktiv am Faschingszug des LMV Lupburg
                                beteiligten und dass noch einmal eine Ein-Tages-Skifahrt in die Flachau organisiert
                                wurde. Bei
                                der 2. Jahreshauptversammlung am 16. April 2004 konnte der 1. Vorstand die ansprechende
                                Zahl von
                                nunmehr 211 Vereinsmitgliedern bekannt geben. Die an diesem Abend turnusgem&auml;&szlig;
                                durchgef&uuml;hrten
                                Neuwahlen erbrachten als neuen Vorsitzenden J&uuml;rgen Lander.</p>

                            <b>3. Vereinsjahr</b>
                            <p>Kurz darauf am 01. Mai 2004 unternahm der Fanclub eine Ausw&auml;rtsfahrt nach K&ouml;ln
                                bevor wir
                                wieder
                                zum Heimspiel gegen den FC Freiburg nach M&uuml;nchen aufbrachen. Die Teilnahmen am
                                Fu&szlig;ballturnier in
                                Dinau (3. Platz) und am Turnier in Steinsberg (Gewinn des Fairnesspokals) kamen dazu,
                                bevor wir
                                endlich beim Turnier in Markstetten den 1. Platz erreichten. Die Pokale in der
                                Vereinsvitrine
                                h&auml;uften sich. Auf Beschluss der Vorstandschaft legte man sich 10 Bierzeltgarnituren
                                als
                                Grundstock f&uuml;r gr&ouml;&szlig;ere und kleinere Vereinsfeste zu. Schon obligatorisch
                                waren
                                Sommernachtsfest
                                und Grillparty. Skiausflug und Weihnachtsfeier, Faschingsball und Faschingszugteilnahme
                                in
                                Lupburg.

                                Hervorzuheben ist, dass eine Rekordbesucherzahl von 307 Personen beim Faschingsball am
                                22.
                                Januar 2005 registriert werden konnte. Am 13. M&auml;rz 2005, einem Sonntag, brachen wir
                                um
                                5.30 Uhr
                                zum Ausw&auml;rtsspiel nach Schalke auf und kamen erst am darauffolgenden Tag um 4.00
                                Uhr
                                fr&uuml;h wieder
                                zur&uuml;ck. Bei der Jahreshauptversammlung am 16. April 2005 konnte ein Mitgliederstand
                                von
                                218 Fans
                                verk&uuml;ndet werden. Der Fanclub wuchs also weiter, wenn auch etwas langsamer.</p>

                            <b>4. Vereinsjahr</b>
                            <p>Mit etwas Wehmut im Herzen machte man sich am 23. April
                                2005 wieder auf nach M&uuml;nchen zum Spiel des FC Bayern gegen den VfL Bochum. Es war
                                unser
                                letztes
                                Stelldichein im Olympiastadion, dem Ort an dem der FC Bayern jahrzehntelang so
                                gro&szlig;e
                                Erfolge
                                gefeiert hatte. Unser Verein zog zum Fu&szlig;ballspielen um in die Allianz-Arena. Neben
                                der
                                Beteiligung am schon traditionellen Fu&szlig;ballturnier in Dinau (3. Platz) waren wir
                                auch
                                beim
                                Fu&szlig;ballturnier in Markstetten vertreten und erlangten den 4. Platz. Neu in diesem
                                Jahr
                                war die
                                Fahrt zur Brauerei Gutmann in Titting, wo wir eine interessante F&uuml;hrung erlebten
                                und
                                trotz des
                                schlechten Wetters gut gelaunt blieben. Nicht unerw&auml;hnt soll bleiben, dass wir bei
                                unserem
                                Faschingsball am 4. Februar 2006 wieder &uuml;be 300 Besucher hatten.</p>
                            <b>5. Vereinsjahr</b>
                            <p>Die Jahreshauptversammlung am 8. April best&auml;tigte den 1. Vorsitzenden J&uuml;rgen
                                Lander in
                                seinem
                                Amt, so dass er f&uuml;r zwei Perioden = 4 Jahre als Vorstand agieren konnte. Bald nach
                                der
                                Generalversammlung fuhren unsere Mitglieder wieder mal zu einem Ausw&auml;rtsspiet und
                                zwar
                                zur
                                Begegnung 1. FC Kaiserslautern gegen den FC Bayern M&uuml;nchen. Im Fritz-Walter-Stadion
                                schaffte der
                                FC Bayern durch ein Unentschieden den 20. Meistertitel und schrieb damit wieder einmal
                                Fu&szlig;ballgeschichte. Man kann sich vorstellen wie ausgelassen wir auf der
                                R&uuml;ckreise waren.
                                Ein
                                paar Wochen sp&auml;ter konnten wir Anja Eichenseer als 250 Fan willkommen hei&szlig;en.
                                Neu war
                                auch, dass
                                wir am 2. September mit einem anderen Programm nach M&uuml;nchen fuhren. An diesem Tag
                                war
                                kein
                                Heimspiel der Bayern. Wir hatten eine etwa einst&uuml;ndige Stadionbesichtigung und
                                fuhren
                                dann
                                weiter in die Innenstadt von M&uuml;nchen. Dort besuchten wir den


                                Viktualienmarkt, das Hofbr&auml;uhaus, das Dallmayer-Kaufuaus, den "Donisl" und kehrten
                                auch
                                im
                                "Augustiner" ein. Dies war mal f&uuml;r unsere Frauen und Kinder eine gelungene
                                Abwechslung.
                                Im
                                Herbst erfolgte auch eine Neuauflage der Weinfahrt nach Obereisenheim. Im Winter
                                engagierten wir
                                uns mit einer Mannschaft beim Hallenpokalturnier des Bayern-Fanclubs Hohenschambach in
                                der
                                Tangrintelhalle in Hemau und erreichten einen 9. Platz. Diesmal spielte die Band
                                "Wilderer" bei
                                unserm Faschingsball auf. Mit einer Skifahrt in die Flachau neigte sich das Vereinsjahr
                                dem Ende
                                entgegen.</p>
                            <b>6. Vereinsjahr</b>
                            <p>Dieses Jahr stand ganz im Zeichen der Vorbereitung auf die Feier Zum 5-j&auml;hrigen
                                Vereinsjubil&auml;um.
                                Das Fest selbst ging vom 15. bis 17. Juni 2007 mit einem Fu&szlig;ballturnier am
                                Sportplatz
                                bei
                                Siedaf&uuml;r &uuml;ber die B&uuml;hne. 16 Mannschaften nahmen daran teil. Es war ein
                                gelungenes Fest
                                und der
                                Wettergott meinte es gut mit uns. Schon eine Woche sp&auml;ter erschien unsere
                                Mannschaft
                                beim
                                Pokalturnier in Dinau (7.Platz) Der 30. Juni/01. Juli sah uns beim
                                Ged&auml;chtnisturnier des
                                Bayern-Fanclubs Steinsberg, wobei wir in einem hochkar&auml;tig besetzten Feld den 3.
                                Platz
                                belegten.
                                Am 17. November 2007 unternahmen wir zum zweiten Mal eine Stadionbesichtigung mit
                                anschlie&szlig;ender
                                Einkehr in den weltber&uuml;hmten Gastst&auml;tten im Herzen M&uuml;nchens. Am 19.
                                Januar 2008 war dann
                                unser
                                Faschingsball im Keltensaal. Es spielte die Band "Froschhaxn-Express'' und es kamen 214
                                Besucher. Am 19. April 2008, bei der Jahreshauptversammlung des Fanclubs, wurden
                                Neuwahlen
                                durchgef&uuml;hrt und weil J&uuml;rgen Lander nicht mehr antrat, mussten die Anwesenden
                                einen
                                neuen 1.
                                Vorstand w&auml;hlen. Weil sich keiner zur Verf&uuml;gung stellen wollte wurde die
                                Neuwahl um 4
                                Wochen auf
                                den 17. Mai 2008 verschoben. Tats&auml;chlich kam es dann erst am 7. Juni zu Neuwahlen,
                                bei
                                denen
                                Matthias Lutz zum 1. Vorsitzenden avancierte und J&uuml;rgen Lander zum 2. Vorsitzenden
                                gew&auml;hlt
                                wurde.</p>
                            <b>7. Vereinsjahr</b>
                            <p>Neu in diesem Jahr war, dass wir zum Tauziehen des OGV Raitenbuch eingeladen wurden und
                                unter
                                vielen teilnehmenden Mannschaften den 1. Platz behaupteten. Ebenfalls Seltenheitswert
                                hatte die

                                Einladung des Bayrischen Ministerpr&auml;sidenten G&uuml;nther Beckstein an die
                                Fanclub-Vorsitzenden wegen
                                des Double-Gewinns in die Staatskanzlei nach M&uuml;nchen. Weil der 1. Vorstand nicht da
                                war
                                fuhr der
                                "Stumpf' nach M&uuml;nchen und konnte die Stars hautnah erleben. In diesem Jahr reisten
                                wir
                                nach
                                M&ouml;nchengladbach zum Ausw&auml;rtsspiel der Bayern und erlebten ein 2 : 2. Am 24.
                                Januar war
                                unser
                                Faschingsball mit der Band "Sigstas" und am 14. Februar unsere Tagesskifahrt ins
                                Brixental zum
                                "Wilden Kaiser". Bei der Jahreshauptversammlung am 18. April 2009 konnte der Vorsitzende
                                einen
                                Mitgliederstand von 276 Personen bekanntgeben.</p>
                            <b>8. Vereinsjahr</b>
                            <p>F&uuml;r Abwechslung im Vereinsleben sorgte nun die Einf&uuml;hrung eines "fliegenden
                                Stammtisches", d.h.
                                man traf sich monatsweise mal beim "Bogner", dann in der "Schwarzen Katz", dann beim
                                "Steinbauer" oder im "Rathausst&uuml;berl" usw. Auf regen Zuspruch stie&szlig; auch der
                                Besuch des
                                "Ritteressens" in Regensburg, ein z&uuml;nftiger Abend mit Gesang, Trinkspr&uuml;chen,
                                gutem Essen
                                und
                                Getr&auml;nken. Zu erw&auml;hnen ist noch der ehrenvolle 2. Platz beim Tauziehen in
                                Raitenbuch und
                                der
                                Faschingsball mit der Band "Roadhouse" im Keltensaal. Abschlie&szlig;end sagte der
                                scheidende
                                1.
                                Vorstand in der Generalversammlung, dass es derzeit 270 Vereinsmitglieder gebe. Die
                                anschlie&szlig;end
                                durchgef&uuml;hrten Neuwahlen brachten Simon Vogl in die Position des 1. Vorstands.</p>
                            <b>9. Vereinsjahr</b>
                            <p>Kaum hatte das 9. Vereinsjahr angefangen mussten wir schon ein "Public-Viewing"
                                organisieren
                                weil der FC Bayern im Endspiel der Champions League stand. Der Gegner war Inter Mailand.
                                Wir
                                hielten es ab im Pausenhof der Schule. Es war der 22. Mai 2010 und die Stimmung war
                                anfangs
                                pr&auml;chtig. Wir verloren unn&ouml;tig mit 2 : 0 und gingen fr&uuml;hmorgens
                                frustriert nach Hause.
                                Ein
                                "fliegender Stammtisch" reihte sich an den anderen und die Weihnachtsfeier wurde diesmal
                                im
                                Gablerkeller in Lupburg abgehalten. Neu war dann das Kesselfleischessen bei Pollinger in
                                Eckertshof am 22.Januar 2011, bevor am 9. April 2011 die n&auml;chste
                                Jahreshauptversammlung
                                staatfand. Der 1. Vorstand hatte ein neues Logo kreiert, das allgemein viel Beifall
                                fand.</p>
                            <b>10. Vereinsjahr</b>


                            <p>Eine Werbung f&uuml;r Neumitglieder mit Flyern und Postern brachte nur eine Person neu
                                zum
                                Verein.
                                Bald wurde ein Festkomitee, bestehend aus 7 Personen geschaffen, welches das
                                10j&auml;hrige
                                Gr&uuml;ndungsfest vorbereiten sollte. "Die 7" nahmen bald die Arbeit auf. Man kam schon
                                im
                                Vorfeld
                                &uuml;berein, ein 300-Mann-Zelt am Sportplatz bei Siedaf&uuml;r aufzustellen, 15 Vereine
                                anzuschreiben,
                                eine Plattenparty abzuhalten und ein Pokalturnier mit 12 Mannschaften zu veranstalten.
                                Bevor das
                                "10-j&auml;hrige" verwirklicht wurde, hielt der Fanclub am 19. Mai 2012 wieder ein
                                "Public
                                Viewing"
                                ab, ging es doch um das denkw&uuml;rdige Endspiel FC Bayern gegen Chelsea und zwar in
                                der
                                Allianz-Arena, sozusagen im eigenen "Wohnzimmer". Nach 120 Minuten stand es noch 1 : 1.
                                Dann kam
                                das unselige Elfmeterschie&szlig;en und unsere Spieler hatten pl&ouml;tzlich "Nerven"
                                und
                                verschossen.
                                Innerhalb von 2 Jahren hatten wir 2 Champions-League-Endspiele verloren und wir
                                &uuml;berlegten
                                ernsthaft, ob wir jemals noch ein "Public Viewing" veranstalten sollten. Am 15. und 16.
                                Juni
                                ging dann das 10-j&auml;hrige Gr&uuml;ndungsfest &uuml;ber die B&uuml;hne, am 15. Juni
                                mit einer
                                Plattenparty und am
                                16. Juni mit dem Pokalturnier, bestehend aus 12 teilnehmenden Mannschaften. Unsere
                                Mannschaft
                                wurde ehrenvoll Dritter. Auf der Jahreshauptversammlung am 22. Juli 2012 standen wieder
                                Neuwahlen an und weil der 1. Vorstand Simon Vogl nicht mehr kandidierte wurde Andreas
                                Graf "auf
                                den Schild" gehoben und J&uuml;rgen Lander zum 2. Vorsitzenden gew&auml;hlt.</p>

                            <b>11. Vereinsjahr</b>
                            <p>Die neue Vorstandschaft engagierte sich gleich am 26. August 2012 beim Hohenfelser
                                B&uuml;rgerfest.
                                Wir warteten mit Zeltbetrieb und gegrilltem Fisch auf, was sehr gut ankam. Am 12. Januar
                                2013
                                beteiligten wir uns zum ersten Mal am Hallenturnier des FCN- Fanclubs-Pyrbaum. Am 19.
                                Januar
                                2013 stieg dann der Faschingsh&ouml;hepunkt, unser Bayernball mit der Band "D'Rebell'n".
                                Ein
                                Highlight in unserer Fanclubgeschichte waren die beiden "Public Viewings" am 25. Mai
                                anl&auml;sslich
                                des Sieges beim Endspiel in der Champions League gegen Dortmund und eine Woche
                                sp&auml;ter am
                                1. Juni
                                2013 beim Pokalsieg. Das "Triple" war geschafft. Wir kamen aus dem Feiern nicht mehr
                                heraus. Am
                                22. Juni 2013 fuhren 10 Mann nach Eckertshof zum Kesselfleischessen. Weil der Andy auch
                                Musik
                                machte, wurde in bester Laune gesungen und gefeiert. Am 6. Juli 2013


                                war dann die Jahreshauptversammlung in der Taverne. Wir hatten aktuell 278
                                Mitglieder.</p>

                            <b>12. Vereinsjahr</b>

                            <p>Am 27. Juli 2013 nahmen wir am Stockturnier in Hohenfels teil. Am 17. Mai 2014
                                sa&szlig;en wir
                                im
                                Olympiastadion in Berlin beim Pokalfinale gegen Borussia Dortmund. In diesem Jahr 2014
                                verstarb
                                auch unser Gr&uuml;ndungsmitglied Iris Jungkunz, die sich stets - solange es ging -
                                f&uuml;r die
                                Belange
                                des Vereins eingesetzt und sich auf vielf&auml;ltige Weise engagiert hatte. Dann war
                                bald
                                schon, am
                                26. Juli 2014 die ordentliche Jahreshauptversammlung. Der Vorsitzende Andreas Graf
                                konnte 275
                                Mitglieder benennen. Weil an diesem Tag auch Neuwahlen anstanden und er sich f&uuml;r
                                eine
                                weitere
                                Periode zur Verf&uuml;gung stellte, hie&szlig; der neue 1. Vorstand wieder Andreas
                                Graf.</p>

                            <b>13. Vereinsjahr</b>

                            <p>Da die Erinnerung an das Vereinsgeschehen der letzten 2 bis 3 Jahre noch ziemlich frisch
                                ist,
                                beschr&auml;nke ich mich auf einige wesentliche Sachen im 13. Jahr unseres Bestehens. Da
                                ist
                                z.B. die
                                Teilnahme am 25. Gr&uuml;ndungsfest des Bayern-Fanclubs B&uuml;chelk&uuml;hn am 2.
                                August 2014, das als
                                Besonderheit ein gelungenes Schubkarrenrennen aufwies. Sp&auml;ter in diesem Jahr, am
                                28.
                                November
                                2014 besuchten wir auch den Weihnachtsmarkt in B&uuml;chelk&uuml;hn. Unser Faschingsball
                                fand am
                                25.
                                Januar 2015 statt und war ein voller Erfolg. Nicht so ganz erfolgreich war das Wachsen
                                unserer
                                Mitgliederzahl. Wir schrumpften sogar von 275 auf 262 Mitglieder.</p>

                            <b>14. Vereinsjahr</b>

                            <p>Wenig Neues, viel Bew&auml;hrtes und schon zur Tradition gewordenes bleibt &uuml;ber das
                                vorletzte
                                Vereinsjahr zu berichten. Gesellige Ereignisse kamen nicht zu kurz: Spalierstehen bei
                                Hochzeiten, Teilnahmen an runden Geburtstagen von Vereinsmitgliedern, Helferessen und
                                als
                                besonderes Erlebnis die Fahrt nach M&uuml;nchen zum Spiel Bayern gegen Wolfsburg, in dem
                                Lewandowski
                                diese sagenhaften Tore schoss und Bayern 5 : 1 gewann. Bei der Jahreshauptversammlung
                                2016
                                stellte sich Andreas Graf nach vierj&auml;hriger erfolgreicher T&auml;tigkeit nicht mehr
                                zur Wahl
                                und
                                Christiane Rychly &uuml;bernahm das Ruder. Sie ist Gr&uuml;ndungsmitglied und seit
                                vielen Jahren
                                in
                                unterschiedlicher Weise in die Vorstandschaft eingebunden.</p>

                        </i>

                        <p>Der Verein hat aktuell {userData.Count} Mitglieder.</p>
                    </article>
                </TabPanel>
                { /* Aktueller Vorstand */}
                <TabPanel value={value} index={1} dir={theme.direction}>
                    <article>
                        <h3>Der aktuelle Vorstand</h3>
                        <p>Am 20.04.2024 wurden die folgenden Mitglieder als unser neuer Vorstand gew&auml;hlt. </p>
                        <ul className="list">
                            <li>Thomas Stadlmeier (1.Vorstand)</li>
                            <li>Andreas Graf (2.Vorstand)</li>
                            <li>Stefan Spangler (3.Vorstand)</li>
                            <li>Lukas Ring (1.Kassierer)</li>
                            <li>Matthias Hofmann (2.Kassierer & Kartenbeauftragter)</li>
                            <li>Thomas Mulzer (1.Schriftf&uuml;hrer)</li>
                            <li>Ren&eacute; Rendant (2.Schriftf&uuml;hrer, Homepage, Fanartikel)</li>
                            <li>Georg Spangler (1.Beisitzer)</li>
                            <li>Stephan Walter (2.Beisitzer)</li>
                            <li>Corina Hofmann (3. Beisitzer)</li>
                            <li>Irene Walter (Kassenpr&uuml;fer)</li>
                            <li>Christiane Rychly (Kassenpr&uuml;fer)</li>
                        </ul>
                        <ImageViewer items={vorstand2024} maxWidth={550} className="vorstand" untertitel="9px"/>
                    </article>
                </TabPanel>
                { /* Mitgliederinformationen */}
                <TabPanel value={value} index={2} dir={theme.direction}>
                    <article>
                        <h3>Mitgliederinformationen</h3>
                        <p>Der FC Bayern Fanclub Forellenbachtal Hohenfels kann seit seiner Gr&uuml;ndung im April 2002
                            eine stetig steigende Mitgliederzahl verbuchen. </p><p>Aktuell hat der
                        Verein<b>&nbsp;{userData.Count}&nbsp;</b>Mitglieder.</p>
                        <p>Werde auch du Mitglied beim FC Bayern Fanclub Hohenfels! F&uuml;lle einfach den Antrag aus
                            und schicke ihn unterschrieben an unseren 1. Schriftf&uuml;hrer:

                            <ErsterSchriftfuehrer/>

                            oder gib ihn einfach bei einem Mitglied der Vorstandschaft ab! Wir freuen uns auf dich!</p>
                        <h4>Mitgliedsantrag</h4>
                        <article>
                            <div className="dekstopAntrag">
                                <PDFViewer source={config.api.pdfPath() + "Mitgliedsantrag_2021_neu.pdf"}/>
                            </div>
                            <div className="mobileAntrag">
                                <a href={config.api.pdfPath() + "Mitgliedsantrag_2021_neu.pdf"} target="_blank">Download
                                    Mitgliedsantrag</a>
                            </div>
                        </article>
                    </article>
                </TabPanel>
                { /* Gedenken */}
                <TabPanel value={value} index={3} dir={theme.direction}>
                    <article>
                        <h3>Im Gedenken</h3>
                        <div id="trauerRahmen">
                            <ImageViewer items={verstorbeneMitglieder} width={200} maxWidth={350} className="gedenken"/>
                        </div>
                    </article>
                </TabPanel>
                { /* Satzung */}
                <TabPanel value={value} index={4} dir={theme.direction}>
                    <article><h3>Unsere Satzung</h3>
                        <h4>&sect; 1 - Name und Sitz</h4>
                        <p>Der Verein f&uuml;hrt den Namen "FC Bayern-Fanclub Forellenbachtal Hohenfels". Er hat seinen
                            Sitz in Hohenfels.</p>
                        <h4>&sect; 2 - Zielsetzung, Zweck</h4>
                        <p>Der Fanclub verfolgt das Ziel, die Geselligkeit unter den Vereinsmitgliedern zu pflegen und
                            das gesellschaftliche und kulturelle Leben in der Gemeinde Hohenfels mit zu
                            unterst&uuml;tzen.
                            Er will es seinen Mitgliedern und befreundeten Personen erm&ouml;glichen, sportliche
                            Begegnungen des FC Bayern M&uuml;nchen mit anderen Mannschaften vor Ort zu verfolgen.
                            Der Verein ist politisch und konfessionell neutral.</p>
                        <h4>&sect; 3 - Mitgliedschaft</h4>
                        <p>Mitglied kann jeder werden, der beim Vorstand um Aufnahme nachsucht. &uuml;ber die Aufnahme
                            entscheidet die Vorstandschaft. Die Mitgliedschaft endet durch Austrittserkl&auml;rung,
                            durch Ausschluss oder Tod. Ein Mitglied kann aus dem Verein ausgeschlossen werden, wenn es
                            in ehrenr&uuml;hriger Weise gegen die Vereinszwecke verst&ouml;&szlig;t, gegen die
                            Vereinssatzung handelt oder seiner Beitragspflicht trotz wiederholter Mahnung nicht
                            nachkommt.</p>
                        <h4>&sect; 4 - Vereinsorgane, Vorstandschaft</h4>
                        <p>Organe des Vereins sind die Vorstandschaft und die &uuml;brigen Mitglieder. Der Vorstand
                            setzt sich zusammen aus:
                            <ul className="list">
                                <li> 1. Vorstand</li>
                                <li> 2. Vorstand</li>
                                <li> 3. Vorstand</li>
                                <li> 1. Kassier</li>
                                <li> 2. Kassier</li>
                                <li> 1. Schriftf&uuml;hrer</li>
                                <li> 2. Schriftf&uuml;hrer</li>
                                <li> 1. Beirat</li>
                                <li> 2. Beirat</li>
                                <li> Homepagebetreuer</li>
                                <li> Kartenbeauftragter</li>
                                <li> Fanartikelbeauftragter</li>
                            </ul>
                            Die zwei Kassenpr&uuml;fer sind mit den Vorstandschaftsmitgliedern zu w&auml;hlen, sie
                            nehmen aber nicht an den Vorstandschaftssitzungen teil.
                            Die Vorstandsschaft wird jeweils f&uuml;r die Dauer von zwei Jahren von der ordentlichen
                            Mitgliederversammlung gew&auml;hlt. Der Vorstand bleibt so lange im Amt, bis ein neuer
                            Vorstand gew&auml;hlt ist. Die Wiederwahl eines Vorstandsmitgliedes ist zul&auml;ssig. Die
                            ordentliche Mitgliederversammlung findet jeweils einmal im Kalenderjahr und zwar am
                            Saisonende statt.
                            Die Mitgliederversammlung beschlie&szlig;t:
                            <ul>
                                <li>a.) die Festsetzung der Mitgliedsbeitr&auml;ge</li>
                                <li> b.) die Wahl oder Abberufung von Vorstandsmitgliedern</li>
                                <li> c.) die Aufl&ouml;sung des Vereins und die Verwendung des Vereinsverm&ouml;gens
                                </li>
                            </ul>
                            Au&szlig;erordentliche Mitgliederversammlungen m&uuml;ssen einberufen werden, wenn
                            mindestens ein
                            Viertel der Mitglieder dies verlangen. Bei der Beschlussfassung in den
                            Mitgliederversammlungen entscheidet die einfache Mehrheit der anwesenden Mitglieder. Nur bei
                            der Entscheidung&uuml;ber die m&ouml;gliche Aufl&ouml;sung m&uuml;ssen zwei Drittel der
                            anwesenden Mitglieder dies wollen.
                        </p>
                        <h4>&sect; 5 - Gesch&auml;ftsbereich des Vorstands</h4>
                        <p>Der erste, der zweite und dritte Vorstand sind gesch&auml;ftsf&uuml;hrende Vorst&auml;nde.
                            Sie vertreten den Verein in den Vereinsangelegenheiten. Der erste und zweite Kassier hat
                            die Vollmacht, den Verein in allen finanziellen Angelegenheiten zu vertreten. Der erste und
                            zweite Schriftf&uuml;hrer ist f&uuml;r den Schriftverkehr des Vereins zust&auml;ndig und im
                            Rahmen dieser Aufgabe zeichnungsberechtigt.</p>
                        <h4>&sect; 6 - Beschlussfassung des Vorstandes</h4>
                        <p>Der Vorstand ist beschlussf&auml;hig wenn alle Vorstandschaftsmitglieder ordentlich
                            eingeladen sind und mindestens ein Drittel des Vorstandes anwesend ist. Der Vorstand
                            entscheidet mit einfacher Stimmenmehrheit. Bei Stimmengleichheit gibt die Stimme des 1.
                            Vorstandes bzw. des die Sitzung leitenden Vorstand den Ausschlag.</p>
                        <h4>&sect; 7 - Mitgliedsbeitr&auml;ge</h4>
                        <p>Die Mitgliedsbeitr&auml;ge werden gegen Mitte des Kalenderjahres im Lastschriftverfahren
                            eingezogen. Sie werden wie folgt erhoben:
                            <ul className="list">
                                <li>Kinder bis 6 Jahre: 1,00 &euro;</li>
                                <li>Kinder und Jugendliche 6 bis 18 Jahre: 9,00 &euro;</li>
                                <li>Erwachsene ab 18 Jahre: 15,00 &euro;</li>
                            </ul></p>
                        <h4>&sect; 8 - Einnahmen insgesamt</h4>
                        <p>Alle Einnahmen (Mitgliedsbeitr&auml;ge, Spenden, Zusch&uuml;sse, Erl&ouml;se und etwaige
                            Gewinne) werden zur Erreichung des satzungsgem&auml;&szlig;en Zwecks verwendet.
                            Ausscheidende Mitglieder haben keinerlei Anspr&uuml;che auf das Vereinsverm&ouml;gen, oder
                            auf R&uuml;ckzahlung eines bereits bezahlten Mitgliedbeitrages.</p>
                        <h4>&sect; 9 - Haftpflicht</h4>
                        <p>F&uuml;r die bei Vereinsveranstaltungen entstehenden etwaigen Sch&auml;den und Sachverluste
                            haftet der Verein den Mitgliedern gegen&uuml;ber nicht.
                        </p>
                        <h4>&sect; 10 - Kassenpr&uuml;fung</h4>
                        <p>Die Pr&uuml;fung der Jahresrechnung hat vor der j&auml;hrlichen ordentlichen
                            Mitgliederversammlung zu erfolgen. Das Ergebnis der Kassenpr&uuml;fung ist der ordentlichen
                            Mitgliederversammlung durch die Kassenpr&uuml;fung mitzuteilen.</p>

                    </article>
                </TabPanel>
            </SwipeableViews>
        </div>
    )
}

export default withRouter(Verein);
