import {createSlice} from '@reduxjs/toolkit'
import {EditUser} from "../app/dto/userData";

export const userSlice = createSlice({
    name: 'userData',
    initialState: {
        succeded: false,
        succededText: "",
        failed: false,
        errorText: "",
        setNewPassword: false,
        confirmRegistration: false,
        importFile: File,
        openDialog: false,
        editUser: {
            Id: null,
            FirstName: '',
            LastName: '',
            Birthday: '',
            FirstMember: false,
            MemberBegin: '',
            FCBOnline: false,
            Newsletter: false,
            PublicPhoto: false,
            PublicBirthday: false,
            BaseChangeDate: '',
            UserDataChanged: false,
            Street: '',
            HomeNumber: '',
            CountryCode: '',
            ZipCode: '',
            City: '',
            Phone: '',
            Mobile: '',
            UserPhoneAndMobile: false,
            Email: '',
            UseEmail: false,
            ContactChangeDate: '',
            UserContactChanged: false,
            BankName: '',
            Owner: '',
            IBAN: '',
            BIC: '',
            IBANSEC: "",
            BICSEC: "",
            PaymentChangeDate: '',
            UserPaymentChanged: false,
            RegisterNumber: "",
            Role: 0,
            MemberTime: 0,
            MemberState: "",
            Age: "",
            MembershipFee: "",
            DeathDay: "",
            IsActive: false,
            useToken: false,
            UserExit: false,
        },
        currentUser: {
            Id: null,
            FirstName: "",
            LastName: "",
            MemberBegin: "",
            Birthday: "",
            PublicBirthday: false,
            PublicPhoto: false,
            Newsletter: false,
            FCBOnline: false,
            FirstMember: false,
            BaseChangeDate: "",
            UserDataChanged: false,
            Email: "",
            Phone: "",
            Mobile: "",
            Street: "",
            HomeNumber: "",
            CountryCode: "",
            ZipCode: "",
            City: "",
            ContactChangeDate: "",
            UserPhoneAndMobile: false,
            UseEmail: false,
            UserContactChanged: false,
            BankName: "",
            Owner: "",
            IBAN: "",
            BIC: "",
            IBANSEC: "",
            BICSEC: "",
            PaymentChangeDate: "",
            UserPaymentChanged: false,
            RegisterNumber: "",
            Role: 0,
            MemberTime: 0,
            MemberState: "",
            Age: "",
            MembershipFee: "",
            DeathDay: "",
            IsActive: false,
            Login: {
                twoFactoryAuthentication: {
                    useToken: false,
                    token: "",
                    qrCodeUrl: "",
                    code: ""
                },
                registrationEmail: "",
                registrationPassword: "",
                registrationLoginNumber: "",
                newPassword: false,
                registration: false,
                login: true,
                loginName: "",
                loginNumber: "",
                loginPassword: "",
                newLoginPasswordConfirmed: "",
                newLoginPassword: ""
            },
            currentEvent: {
                eventId: '',
                userId: '',
                firstName: "",
                lastName: "",
                memberTime: 0,
                firstMember: "",
                registerNumber: "",
                gemischterSalat: false,
                kartoffelsalat: false,
                nudelsalat: false,
                wurstsalat: false,
                gurkensalat: false,
                tomatensalat: false,
                kuchen: false,
                partner: false,
                kinderBis7: 0,
                kinderAb7: 0,
                vater: false,
                mutter: false,
                unkostenbeitrag: false,
                bemerkungen: '',
                angemeldet: false
            },
            Events: []
        },
        Items: new Array<EditUser>(),
        Count: 0
    },
    reducers: {
        login: (state) => {
            // meldet den Benutzer an
        },
        logout: (state) => {
            state.succeded = false;
            state.succededText = "";
            state.failed = false;
            state.errorText = "";
            state.setNewPassword = false;
            state.confirmRegistration = false;
            state.currentUser.Id = null;
            state.currentUser.FirstName = "";
            state.currentUser.LastName = "";
            state.currentUser.RegisterNumber = "";
            state.currentUser.Email = "";
            state.currentUser.Role = 0;
            state.currentUser.Login.registrationEmail = "";
            state.currentUser.Login.registrationPassword = "";
            state.currentUser.Login.registrationLoginNumber = "";
            state.currentUser.Login.newPassword = false;
            state.currentUser.Login.registration = false;
            state.currentUser.Login.login = true;
            state.currentUser.Login.loginName = "";
            state.currentUser.Login.loginNumber = "";
            state.currentUser.Login.loginPassword = "";
            state.currentUser.Login.newLoginPasswordConfirmed = "";
            state.currentUser.Login.newLoginPassword = "";
            state.currentUser.Login.twoFactoryAuthentication = {
                useToken: false,
                token: "",
                qrCodeUrl: "",
                code: ""
            };
            state.currentUser.BankName = "";
            state.currentUser.BIC = "";
            state.currentUser.BICSEC = "";
            state.currentUser.IBAN = "";
            state.currentUser.IBANSEC = "";
            state.currentUser.Owner = "";
            state.currentUser.Phone = "";
            state.currentUser.Mobile = "";
            state.currentUser.Street = "";
            state.currentUser.HomeNumber = "";
            state.currentUser.CountryCode = "";
            state.currentUser.ZipCode = "";
            state.currentUser.City = "";
            state.currentUser.UseEmail = false;
            state.currentUser.UserPhoneAndMobile = false;
            state.currentUser.PublicBirthday = false;
            state.currentUser.PublicPhoto = false;
            state.currentUser.BaseChangeDate = "";
            state.currentUser.ContactChangeDate = "";
            state.currentUser.PaymentChangeDate = "";
            state.currentUser.Events = [];
            state.currentUser.currentEvent = {
                eventId: '',
                userId: '',
                firstName: "",
                lastName: "",
                memberTime: 0,
                firstMember: "",
                registerNumber: "",
                gemischterSalat: false,
                kartoffelsalat: false,
                nudelsalat: false,
                wurstsalat: false,
                gurkensalat: false,
                tomatensalat: false,
                kuchen: false,
                partner: false,
                kinderBis7: 0,
                kinderAb7: 0,
                vater: false,
                mutter: false,
                unkostenbeitrag: false,
                bemerkungen: '',
                angemeldet: false
            };
        },
        currentUserData: (state) => {
            // lädt die Benutzerstammdaten
        },
        currentUserDataSucceded: (state, action) => {
            state.currentUser.Id = action.payload.Id;
            state.currentUser.Role = action.payload.Role;
            state.currentUser.FirstName = action.payload.FirstName;
            state.currentUser.LastName = action.payload.LastName;
            state.currentUser.Birthday = action.payload.Birthday;
            state.currentUser.FCBOnline = action.payload.FCBOnline;
            state.currentUser.Newsletter = action.payload.Newsletter;
            state.currentUser.PublicPhoto = action.payload.PublicPhoto;
            state.currentUser.PublicBirthday = action.payload.PublicBirthday;
            state.currentUser.FirstMember = action.payload.FirstMember;
            state.currentUser.MemberBegin = action.payload.MemberBegin;
            state.currentUser.BaseChangeDate = action.payload.BaseChangeDate;
            state.currentUser.Street = action.payload.Street;
            state.currentUser.HomeNumber = action.payload.HomeNumber;
            state.currentUser.CountryCode = action.payload.CountryCode;
            state.currentUser.ZipCode = action.payload.ZipCode;
            state.currentUser.City = action.payload.City;
            state.currentUser.Phone = action.payload.Phone;
            state.currentUser.Email = action.payload.Email;
            state.currentUser.Mobile = action.payload.Mobile;
            state.currentUser.UseEmail = action.payload.UseEmail;
            state.currentUser.UserPhoneAndMobile = action.payload.UserPhoneAndMobile;
            state.currentUser.ContactChangeDate = action.payload.ContactChangeDate;
            state.currentUser.BankName = action.payload.BankName;
            state.currentUser.Owner = action.payload.Owner;
            state.currentUser.IBAN = action.payload.IBAN;
            state.currentUser.IBANSEC = action.payload.IBANSEC;
            state.currentUser.BIC = action.payload.BIC;
            state.currentUser.BICSEC = action.payload.BICSEC;
            state.currentUser.PaymentChangeDate = action.payload.PaymentChangeDate;
            state.currentUser.Login.twoFactoryAuthentication = {
                useToken: action.payload.UseToken,
                token: action.payload.Token,
                qrCodeUrl: "",
                code: ""
            };
            state.currentUser.Events = action.payload.Events;
        },

        confirmRegistrationData: (state) => {
            // schließt die Registrierung ab
        },
        setNewPasswordData: (state) => {
            // setzt das neue Benutzerpassword
        },
        saveEditUserData: (state) => {
            // speichert die Daten des bearbeiteten Benutzers
        },
        saveUserBaseData: (state) => {
            // speichert die Benutzerstammdatend
        },
        saveUserContactData: (state) => {
            // speichert die Kontaktdaten
        },
        startImportUserData: (state) => {
            // importiert die Benutzerdaten
        },
        saveUserPaymentData: (state) => {
            // speichert die Bankverbindungsdaten
        },
        saveUserAuthData: (state) => {
            // speichert die Authentifizierungstoken
        },
        getQrCodeUrlValue: (state) => {
            // speichert die Authentifizierungstoken
        },
        checkSecureCode: (state) => {
            // prüft, ob der eigegebene Code gültig ist
        },
        resetUseTokenData: (state) => {
            // set die Verwendung des Token zurück
        },
        getUserEvents: (state) => {
            // holt die Events, an denen der aktuelle Benutzer bereits angemeldet ist
        },
        removeFromEvent: (state) => {
            // entfernt die Benutzer-Event-Zuordnung
        },

        newPassword: (state) => {
            // sendet einen Bestätigungslink zum Anfordern des neuen Kennwortes
        },
        newRegistration: (state) => {
            // setzt das Passwort am Benutzer und sendet eine Bestätigungsemail über die angeforderte Registrierung
        },
        setEditGridDialogOpen: (state, action) => {
            state.openDialog = action.payload;
        },
        setCurrentEventUserData: (state, action) => {
            state.currentUser.currentEvent.userId = action.payload.value.Id;
            state.currentUser.currentEvent.firstName = action.payload.value.FirstName;
            state.currentUser.currentEvent.lastName = action.payload.value.LastName;
            state.currentUser.currentEvent.firstMember = action.payload.value.FirstMember;
            state.currentUser.currentEvent.memberTime = action.payload.value.MemberTime;
            state.currentUser.currentEvent.registerNumber = action.payload.value.RegisterNumber;
        },
        setCurrentEventData: (state, action) => {
            if (state.currentUser.currentEvent.hasOwnProperty(action.payload.key)) {
                // @ts-ignore
                state.currentUser.currentEvent[action.payload.key] = action.payload.value;
            }
        },
        resetCurrentEventData: (state) => {
            state.currentUser.currentEvent.gemischterSalat = false;
            state.currentUser.currentEvent.kartoffelsalat = false;
            state.currentUser.currentEvent.nudelsalat = false;
            state.currentUser.currentEvent.wurstsalat = false;
            state.currentUser.currentEvent.gurkensalat = false;
            state.currentUser.currentEvent.tomatensalat = false;
            state.currentUser.currentEvent.kuchen = false;
            state.currentUser.currentEvent.partner = false;
            state.currentUser.currentEvent.kinderAb7 = 0;
            state.currentUser.currentEvent.kinderBis7 = 0;
            state.currentUser.currentEvent.mutter = false;
            state.currentUser.currentEvent.vater = false;
            state.currentUser.currentEvent.bemerkungen = "";
            state.currentUser.currentEvent.unkostenbeitrag = false;
        },
        getUserEventsSucceded: (state, action) => {
            state.currentUser.Events = action.payload.Events;
        },

        resetUseTokenSucceded: (state, action) => {
            state.currentUser.Login.twoFactoryAuthentication.useToken = action.payload.UseToken;
            state.currentUser.Login.twoFactoryAuthentication.code = "";
        },
        setImportFile: (state, action) => {
            state.importFile = action.payload.importFile;
        },
        setQrCodeUrlAndToken: (state, action) => {
            state.currentUser.Login.twoFactoryAuthentication.qrCodeUrl = action.payload.Url;
            state.currentUser.Login.twoFactoryAuthentication.token = action.payload.Token;
            state.currentUser.Login.twoFactoryAuthentication.useToken = action.payload.UseToken;
            state.currentUser.Login.twoFactoryAuthentication.code = "";
        },
        checkRegistrationData: (state) => {
            // // Prüft, ob der Zeitraum zum Abschluss der Registrierung  schon abgelaufen ist
        },
        checkRegistrationDataSucceded: (state, action) => {
            state.currentUser.Id = action.payload.key;
            state.setNewPassword = false;
            state.confirmRegistration = true;
            state.currentUser.Login.login = false;
            state.currentUser.Login.registration = false;
            state.currentUser.Login.newPassword = false;
        },
        checkRegistrationDataFailed: (state, action) => {
            state.currentUser.Id = action.payload.key;
            state.setNewPassword = false;
            state.confirmRegistration = false;
            state.currentUser.Login.login = false;
            state.currentUser.Login.registration = true;
            state.currentUser.Login.newPassword = false;
        },
        showTokenDialog: (state, action) => {
            state.currentUser.Id = action.payload.Id;
            state.currentUser.Login.twoFactoryAuthentication.token = action.payload.Token;
            state.currentUser.Login.twoFactoryAuthentication.useToken = action.payload.UseToken;
        },
        loginSucceded: (state, action) => {
            state.currentUser.Id = action.payload.Id;
            state.currentUser.FirstName = action.payload.FirstName;
            state.currentUser.LastName = action.payload.LastName;
            state.currentUser.Email = action.payload.Email;
            state.currentUser.RegisterNumber = action.payload.RegisterNumber;
            state.currentUser.Role = action.payload.Role;
            state.currentUser.Login.loginPassword = "";
            state.currentUser.Login.twoFactoryAuthentication.token = "";
            state.currentUser.Login.twoFactoryAuthentication.useToken = false;
        },
        loginFailed: (state, action) => {
            state.currentUser.Id = null;
            state.currentUser.FirstName = "";
            state.currentUser.LastName = "";
            state.currentUser.Email = "";
            state.currentUser.RegisterNumber = "";
            state.currentUser.Role = 0;
            state.currentUser.Login.loginPassword = "";
        },
        setNewPasswordDialog: (state, action) => {
            state.currentUser.Id = action.payload.key;
            state.setNewPassword = action.payload.setNewPassword;
            state.confirmRegistration = false;
            state.currentUser.Login.login = !action.payload.setNewPassword;
            state.currentUser.Login.registration = false;
            state.currentUser.Login.newPassword = false;
        },
        resetLoginSate: (state) => {
            // state.currentUser.Id = action.payload.Id;
            state.confirmRegistration = false;
            state.currentUser.Login.login = true;
            state.currentUser.Login.registration = false;
            state.currentUser.Login.newPassword = false;
        },
        setUserId: (state, action) => {
            state.currentUser.Id = action.payload.Id;
            // state.confirmRegistration = action.payload.confirmRegistration;
            // state.currentUser.Login.login = false;
            // state.currentUser.Login.registration = !action.payload.confirmRegistration;
            // state.currentUser.Login.newPassword = false;
        },
        confirmRegistration: (state, action) => {
            state.currentUser.Id = action.payload.key;
            state.setNewPassword = false;
            state.confirmRegistration = action.payload.confirmRegistration;
            state.currentUser.Login.login = !action.payload.confirmRegistration;
            state.currentUser.Login.registration = false;
            state.currentUser.Login.newPassword = false;
        },
        getActiveUsers: (state) => {
            // lest alle aktiven Benutzer
        },
        setActiveUsersData: (state, action) => {
            state.Items = action.payload;
            state.Count = state.Items.length;
        },
        getActiveUsersCount: (state) => {
            // lest alle aktiven Benutzer
        },
        setActiveUsersDataCount: (state, action) => {

            state.Count = action.payload;
        },
        setEditUserData: (state, action) => {
            const name = action.payload.key;
            if (state.editUser.hasOwnProperty(name)) {
                // @ts-ignore
                state.editUser[name] = action.payload.value;
            }
        },

        setEditUserDataObject: (state, action) => {
            let data = {
                Id: null,
                FirstName: '',
                LastName: '',
                Birthday: '',
                FirstMember: false,
                MemberBegin: '',
                FCBOnline: false,
                Newsletter: false,
                PublicPhoto: false,
                PublicBirthday: false,
                BaseChangeDate: '',
                UserDataChanged: false,
                Street: '',
                HomeNumber: '',
                CountryCode: '',
                ZipCode: '',
                City: '',
                Phone: '',
                Mobile: '',
                UserPhoneAndMobile: false,
                Email: '',
                UseEmail: false,
                ContactChangeDate: '',
                UserContactChanged: false,
                BankName: '',
                Owner: '',
                IBAN: '',
                BIC: '',
                IBANSEC: "",
                BICSEC: "",
                PaymentChangeDate: '',
                UserPaymentChanged: false,
                RegisterNumber: "",
                Role: 0,
                MemberTime: 0,
                MemberState: "",
                Age: "",
                MembershipFee: "",
                DeathDay: "",
                IsActive: false,
                useToken: false,
                UserExit: false,
            };
            for (let key in action.payload.value) {
                if (data.hasOwnProperty(key)) {
                    // @ts-ignore
                    data[key] = action.payload.value[key];
                }
            }
            state.editUser = data;
        },
        setUserData: (state, action) => {
            const name = action.payload.key;
            if (name === "Password") {
                // @ts-ignore
                state.currentUser.Login.loginPassword = action.payload.value;
            } else if (name === "newLoginPassword") {
                // @ts-ignore
                state.currentUser.Login.newLoginPassword = action.payload.value;
            } else if (name === "newLoginPasswordConfirmed") {
                // @ts-ignore
                state.currentUser.Login.newLoginPasswordConfirmed = action.payload.value;
            } else if (name === "useToken") {
                // @ts-ignore
                state.currentUser.Login.twoFactoryAuthentication.useToken = action.payload.value;
            } else if (state.currentUser.hasOwnProperty(name)) {
                // @ts-ignore
                state.currentUser[name] = action.payload.value;
            } else if (name === "code") {
                // @ts-ignore
                state.currentUser.Login.twoFactoryAuthentication.code = action.payload.value;
            }
        },
        setLoginData: (state, action) => {
            const name = action.payload.key;
            if (name === "setNewPassword" || name === "registration") {
                state.currentUser.Login.login = !action.payload.value;
                state.currentUser.Login.loginName = "";
                state.currentUser.Login.loginNumber = "";
                state.currentUser.Login.loginPassword = "";
                state.currentUser.Login.newPassword = false;
                state.currentUser.Login.registration = false;
            }

            if (name === "registration" && action.payload.value === false) {
                state.currentUser.Login.registrationEmail = "";
                state.currentUser.Login.registrationPassword = "";
                state.currentUser.Login.registrationLoginNumber = "";
            }
            if (name === "registration") {
                state.currentUser.Login.registration = true;
            }

            if (name === "setNewPassword") {
                state.currentUser.Login.newPassword = action.payload.value;
            }

            if (name === "registrationPasswordConfirmed") {
                state.currentUser.Login.registrationPassword = action.payload.value;
            }

            if (state.currentUser.Login.hasOwnProperty(name)) {
                // @ts-ignore
                state.currentUser.Login[name] = action.payload.value;
            }
        },

    }
})

export const {
    login,
    loginSucceded,
    showTokenDialog,
    loginFailed,
    logout,
    setLoginData,
    setUserData,
    setEditUserData,

    setEditUserDataObject,
    getActiveUsers,
    getActiveUsersCount,
    setActiveUsersData,
    setActiveUsersDataCount,
    setNewPasswordDialog,
    setEditGridDialogOpen,
    confirmRegistration,
    confirmRegistrationData,
    setNewPasswordData,
    checkRegistrationData,
    currentUserData,
    currentUserDataSucceded,
    saveUserBaseData,
    saveUserPaymentData,
    saveUserContactData,
    saveUserAuthData,
    getQrCodeUrlValue,
    setQrCodeUrlAndToken,
    checkSecureCode,
    resetUseTokenData,
    resetUseTokenSucceded,
    setImportFile,
    startImportUserData,
    setUserId,
    resetLoginSate,
    getUserEvents,
    getUserEventsSucceded,
    removeFromEvent,
    setCurrentEventData,
    newPassword,
    newRegistration,
    saveEditUserData,
    setCurrentEventUserData,
    resetCurrentEventData
} = userSlice.actions
export const currentUser = (state: any) => state.currentUser;

export default userSlice.reducer
