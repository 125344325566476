import {call, fork, put, select, takeLatest} from "redux-saga/effects";
import {AppState} from "../app/appState";
import {AxiosResponse} from "axios";
import * as service from "../app/axiosService";
import {ContactFormData, RegisterEvent} from "../app/dto/appData";
import {
    addContact,
    getAllEvents,
    sendNewsletterMail,
    setAllEvents,
    showFailedSnackbar,
    showSuccededSnackbar,
    updateEvents,
    updateEventsSuccess
} from "../reducers/appReducer";


function* sendContactData() {
    try {
        const contactFormData: ContactFormData = yield select((state: AppState) => state.appData.contactFormular);
        const response: AxiosResponse = yield call(service.sendContactData(contactFormData));
        let data = JSON.parse(response.data);
        // console.log(data);
        if (data === false || data === null || data === undefined) {
            yield put(showFailedSnackbar({message: "Deine Nachricht konnte nicht gesendet werden. Konkatiere uns bitte per E-Mail: webadmin@fcb-hohenfels.de"}));
        } else if (data === true) {
            yield put(showSuccededSnackbar({message: "Deine Nachricht wurde versand. Wir melden uns umgehend bei dir."}));
        }
    } catch (e) {
        console.log(e);
    }
}


function* watchAddContact() {
    yield takeLatest(addContact.type, sendContactData)
}


function* sendNewsletterData() {
    try {
        const mailBody: string = yield select((state: AppState) => state.appData.NewsletterText);
        const response: AxiosResponse = yield call(service.sendNewsletter(mailBody));
        let data = response.data;
        console.log(data);
        if (data.SendNewsletter) {
            yield put(showSuccededSnackbar({message: "Nachricht wurde versendet."}));
        } else {
            yield put(showFailedSnackbar({message: "Nachricht konnte nicht versendet werden."}));
        }
    } catch (e) {
        console.log(e);
        yield put(showFailedSnackbar({message: "Nachricht konnte nicht versendet werden. "}));
    }
}


function* watchSendNewsletter() {
    yield takeLatest(sendNewsletterMail.type, sendNewsletterData)
}

function* getAllEventsData() {
    try {
        const response: AxiosResponse = yield call(service.getAllEventsData());
        let data = response.data;
        const response2: AxiosResponse = yield call(service.getAllEventTypesData());
        let data2 = response2.data;

        yield put(setAllEvents({Events: data.Result, EventTypes: data2.Result}));
    } catch (e) {
        console.log(e);
    }
}

function* watchGetAllEventsData() {
    yield takeLatest(getAllEvents.type, getAllEventsData)
}

function* updateOrAddEventData() {
    try {
        const events: RegisterEvent[] = yield select((state: AppState) => state.appData.events);
        const currentEvent: RegisterEvent = yield select((state: AppState) => state.appData.currentEvent);
        let newEvents: RegisterEvent[] = events.map((event) => {
            if (event.Id = currentEvent.Id) {
                return currentEvent;
            } else {
                return event;
            }
        });
        //alert(JSON.stringify(newEvents));
        yield updateEventsSuccess(newEvents);
    } catch (e) {
        console.log(e);
    }
}

function* watchUpdateOrAddEvent() {
    yield takeLatest(updateEvents.type, updateOrAddEventData)
}

const appSaga = [
    fork(watchSendNewsletter),
    fork(watchGetAllEventsData),
    fork(watchAddContact),
    fork(watchUpdateOrAddEvent)
];


export default appSaga;






