import "../sass/content.sass";
import React from "react";

function Spieltag(props: any) {
    let spieltag = props.spieltag;
    if (spieltag === undefined)
        spieltag = 1;
    let wochentag = props.wochentag;
    if (wochentag === undefined)
        wochentag = 'Samstag';
    let datum = props.datum;
    if (datum === undefined)
        datum = '';
    let anstoss = props.anstoss;
    if (anstoss === undefined)
        anstoss = '15:30';
    let heimmannschaft = props.heimmannschaft;
    if (heimmannschaft === undefined)
        heimmannschaft = 'FC Bayern München';
    let gastmannschaft = props.gastmannschaft;
    if (gastmannschaft === undefined)
        spieltag = 'FC Bayern München';
    let ergebnis = props.ergebnis;
    if (ergebnis === undefined)
        ergebnis = '0 : 0';
    return (
        <div className="spieltag">
            <h4>{spieltag}. Spieltag - {wochentag}, {datum} ({anstoss})</h4>
            <p>{heimmannschaft} - {gastmannschaft}</p>
            <b className="result">{ergebnis}</b>
        </div>
    )
}

export default Spieltag;
