import React, {useEffect, useState} from 'react';
import {FormControlLabel, Switch, TextField} from "@material-ui/core";


const ContactData = (props: any) => {

    const ContactInitial = {
        Street: {
            error: false,
            helperText: ''
        },
        HomeNumber: {
            error: false,
            helperText: ''
        },
        CountryCode: {
            error: false,
            helperText: ''
        },
        ZipCode: {
            error: false,
            helperText: ''
        },
        City: {
            error: false,
            helperText: ''
        },
        Email: {
            error: false,
            helperText: ''
        }
    };

    const [Contact, setContactState] = React.useState(ContactInitial);
    useEffect(() => {
    }, []);

    const setNewState = (event: React.ChangeEvent<HTMLInputElement>) =>{
        if(props.setNewState){
            props.setNewState(event);
        }
    }

    return (
        <div>
            <ul className="floatList">
                <li style={{width: "75%"}}>
                    <TextField className="kontakt-feld"
                               id="Street"
                               label="Stra&szlig;e"
                               type="text"
                               fullWidth
                               value={props.data.Street}
                               onChange={setNewState}
                               error={Contact.Street.error}
                               helperText={Contact.Street.helperText}
                               required
                    />
                </li>
                <li style={{width: "15%"}}>
                    <TextField className="kontakt-feld"
                               id="HomeNumber"
                               label="Nr."
                               type="text"
                               fullWidth
                               value={props.data.HomeNumber}
                               onChange={setNewState}
                               error={Contact.HomeNumber.error}
                               helperText={Contact.HomeNumber.helperText}
                               required
                    />
                </li>
                <li style={{width: "10%"}}>
                    <TextField className="kontakt-feld"
                               id="CountryCode"
                               label="Land"
                               type="text"
                               fullWidth
                               value={props.data.CountryCode}
                               onChange={setNewState}
                               error={Contact.CountryCode.error}
                               helperText={Contact.CountryCode.helperText}
                               required
                    />
                </li>
                <li style={{width: "15%"}}>
                    <TextField className="kontakt-feld"
                               id="ZipCode"
                               label="PLZ"
                               type="text"
                               fullWidth
                               value={props.data.ZipCode}
                               onChange={setNewState}
                               error={Contact.ZipCode.error}
                               helperText={Contact.ZipCode.helperText}
                               required
                    />
                </li>
                <li style={{width: "63.5%"}}>
                    <TextField className="kontakt-feld"
                               id="City"
                               label="Ort"
                               type="text"
                               fullWidth
                               value={props.data.City}
                               onChange={setNewState}
                               error={Contact.City.error}
                               helperText={Contact.City.helperText}
                               required
                    />
                </li>
                <li style={{width: "45%"}}>
                    <TextField className="kontakt-feld"
                               id="Phone"
                               label="Telefon"
                               type="text"
                               fullWidth
                               value={props.data.Phone}
                               onChange={setNewState}
                    />
                </li>
                <li style={{width: "45%"}}>
                    <TextField className="kontakt-feld"
                               id="Mobile"
                               label="Handy"
                               type="text"
                               fullWidth
                               value={props.data.Mobile}
                               onChange={setNewState}
                    />
                </li>
                <li style={{width: "91.8%"}}>
                    <FormControlLabel control={
                        <Switch
                            defaultChecked
                            color="primary"
                            id="UserPhoneAndMobile"
                            checked={props.data.UserPhoneAndMobile}
                            onChange={setNewState}
                            inputProps={{'aria-label': 'Nutzung Telefonnummern'}}
                        />} label="Ich bin damit einverstanden, dass die von mir angegebene(n) Telefonnummer(n) zur Durchf&uuml;hrung des Mitgliedschaftsverh&auml;ltnisses durch den Vereinsvorstand genutzt und hierf&uuml;r auch an andere Vereinsmitglieder (z. B. zur Kontaktaufnahme) weitergegeben werden
darf/d&uuml;rfen."/>
                </li>
                <li style={{width: "91.8%"}}>
                    <TextField className="kontakt-feld"
                               id="Email"
                               label="E-Mail"
                               type="text"
                               fullWidth
                               value={props.data.Email}
                               onChange={setNewState}
                               error={Contact.Email.error}
                               helperText={Contact.Email.helperText}
                               required
                    />
                </li>
                <li style={{width: "91.8%"}}>
                    <FormControlLabel control={
                        <Switch
                            defaultChecked
                            color="primary"
                            id="UseEmail"
                            checked={props.data.UseEmail}
                            onChange={setNewState}
                            inputProps={{'aria-label': 'Nutzung Email'}}
                        />}
                                      label="Ich bin damit einverstanden, dass meine o. g. E-Mail-Adresse (sofern angegeben) ebenfalls zu Vereinszwecken an andere Vereinsmitglieder (z. B. zur Kontaktaufnahme) weitergegeben werden darf."/>
                </li>
                <li>
                    <TextField className="kontakt-feld"
                               id="ContactChangeDate"
                               label="zuletzt ge&auml;ndert"
                               type="date"
                               defaultValue=""
                               InputLabelProps={{
                                   shrink: true,
                               }}
                               fullWidth
                               value={props.data.ContactChangeDate}
                               disabled={true}
                    />
                </li>
            </ul>
        </div>
    )

}

export default ContactData;
