import React, {useEffect, useState} from 'react';
import {FormControlLabel, Switch, TextField} from "@material-ui/core";



const BaseData = (props: any) => {

    const BaseInitial = {
        FirstName: {
            error: false,
            helperText: ''
        },
        LastName: {
            error: false,
            helperText: ''
        },
        Birthday: {
            error: false,
            helperText: ''
        }
    };

    useEffect(() => {
    }, []);

    const [Base, setBaseState] = React.useState(BaseInitial);

    const setNewState = (event: React.ChangeEvent<HTMLInputElement>) =>{
        if(props.setNewState){
            props.setNewState(event);
        }
    }

    return (
       <div>
           {!props.isAdmin &&
           <div className="infoBox">
               <b>Beachte bitte folgenden Hinweis</b><br/>
               Du kannst die Einwilligung in die Datenverarbeitung der E-Mail-Adresse sowie in die
               Weitergabe der Telefonnummer(n) an andere Vereinsmitglieder, der Weitergabe der
               Personen- und Kontaktdaten an den FC Bayern M&uuml;nchen, die Einwilligung f&uuml;r Foto-
               und
               Videoaufnahmen, die Einwilligung zur Ver&ouml;ffentlichung der Personendaten
               (Gru&szlig;karte zum
               Geburtsag) und die Einwilligung zum Empfang von Newslettern und Marketingma&szlig;nahmen
               jederzeit
               ganz oder teilweise mit Wirkung f&uuml;r die Zukunft widerrufen. Der
               Widerruf kann in Textform (Brief oder E-Mail) gegen&uuml;ber dem Vorstand
               (<b>vorstand@fcb-hohenfels.de</b>) oder der Mitgliederverwaltung
               (<b>mitgliedschaft@fcb-hohenfels.de</b>) erfolgen. Bei Widerruf mit Brief ist dieser an die
               im Impressum (<a
               href="https://www.fcb-hohenfels.de/#/Impressum">https://www.fcb-hohenfels.de/#/Impressum</a>)
               ver&ouml;ffentlichten Anschrift zu richten. Der Widerruf kann auch durch Bearbeitung der
               pers&ouml;nlichen Daten hier im Mitgliederbereich erfolgen.
           </div>
           }
           <ul className="floatList">
               <li style={{width: "42.5%"}}>
                   <TextField className="kontakt-feld"
                              id="FirstName"
                              label="Vorname"
                              type="text"
                              fullWidth
                              value={props.data.FirstName}
                              onChange={setNewState}
                              error={Base.FirstName.error}
                              helperText={Base.FirstName.helperText}
                              required/>
               </li>
               <li style={{width: "42.5%"}}>
                   <TextField className="kontakt-feld"
                              id="LastName"
                              label="Nachname"
                              type="text"
                              fullWidth
                              value={props.data.LastName}
                              onChange={setNewState}
                              error={Base.LastName.error}
                              helperText={Base.LastName.helperText}
                              required/>
               </li>
               <li style={{width: "45%"}}>
                   <TextField className="kontakt-feld"
                              id="Birthday"
                              label="Geburtsdatum"
                              type="date"
                              InputLabelProps={{
                                  shrink: true,
                              }}
                              fullWidth
                              defaultValue=""
                              value={props.data.Birthday}
                              onChange={setNewState}
                              error={Base.Birthday.error}
                              helperText={Base.Birthday.helperText}
                              required/>
               </li>
               <li style={{width: "45%"}}>
                   <FormControlLabel control={
                       <Switch
                           disabled={true}
                           defaultChecked
                           color="primary"
                           id="FirstMember"
                           checked={props.data.FirstMember}
                           onChange={setNewState}
                           inputProps={{'aria-label': 'G&uuml;ndungsmitglied'}}
                       />} label="G&uuml;ndungsmitglied"/>
               </li>
               <li style={{width: "35%"}}>
                   <TextField className="kontakt-feld"
                              id="MemberBegin"
                              label="Mitglied seit"
                              type="date"
                              InputLabelProps={{
                                  shrink: true,
                              }}
                              fullWidth
                              defaultValue=""
                              value={props.data.MemberBegin}
                              disabled={true}/>
               </li>
               <li style={{width: "91.8%"}}>
                   {!props.isAdmin && <div className="infoBox">
                       <b>Folgende Daten werden an den FC Bayern M&uuml;nchen &uuml;bermittelt</b><br/>
                       <ul className="list">
                           <li> Name</li>
                           <li> Vorname</li>
                           <li> Anschrift (Stra&szlig;e/Hausnummer)</li>
                           <li> PLZ</li>
                           <li> Ort</li>
                           <li> Land</li>
                           <li> Geburtsdatum</li>
                           <li> Telefonnummer</li>
                           <li> Handynummer</li>
                           <li> E-Mail-Adresse</li>
                       </ul>
                   </div>}
                   <FormControlLabel control={
                       <Switch
                           defaultChecked
                           color="primary"
                           id="FCBOnline"
                           checked={props.data.FCBOnline}
                           onChange={setNewState}
                           inputProps={{'aria-label': 'Daten an den FC Bayern M&uuml;nchen &uuml;bermitteln'}}
                       />}
                                     label="Ich bin damit einverstanden, da&szlig; meine Personen- und Kontaktdaten an den FC Bayern M&uuml;nchen im Rahmen der Fanclubverwaltung &uuml;bermittelt werden."/>

               </li>
               <li style={{width: "91.8%"}}>
                   {!props.isAdmin && <p>
                       Die Webseite des Fanclubs bietet seinen Mitgliedern die M&ouml;glichkeit an, in
                       unregelm&auml;&szlig;igen
                       Abst&auml;nden per E-Mail &uuml;ber Neuigkeiten aus dem Fanclubleben &uuml;ber einen
                       Newsletter
                       per EMail
                       informiert zu werden.
                   </p>}
                   <FormControlLabel control={
                       <Switch
                           defaultChecked
                           color="primary"
                           id="Newsletter"
                           checked={props.data.Newsletter}
                           onChange={setNewState}
                           inputProps={{'aria-label': 'Newsletter abonieren'}}
                       />}
                                     label="Ich bin damit einverstanden, da&szlig; Newsletter und Marketingma&szlig;nahmen des Fanclubs zugestellt werden."/>


               </li>
               <li style={{width: "91.8%"}}>
                   <FormControlLabel control={
                       <Switch
                           defaultChecked
                           color="primary"
                           id="PublicPhoto"
                           checked={props.data.PublicPhoto}
                           onChange={setNewState}
                           inputProps={{'aria-label': 'Bilder, auf denen ich zu sehen bin, zur Ver&ouml;ffentlichen freigeben'}}
                       />}
                                     label="Ich bin damit einverstanden, da&szlig; Foto- und Videoaufnahmen bei Veranstaltungen des Fanclubs (z.B. gemeinsame Stadionbesuche, Veranstaltungen) angefertigt und auf der Webseite des Fanclubs sowie in regionalen Presseerzeugnissen ver&ouml;ffentlicht werden d&uuml;rfen."/>
                   {!props.isAdmin && <div className="infoBox"><p>
                       Du bist hiermit darauf hingewiesen worden, dass auch ohne deine ausdr&uuml;ckliche
                       Einwilligung Foto- und Videoaufnahmen von deiner Person im Rahmen der Teilnahme an
                       &ouml;ffentlichen Veranstaltungen des Vereins – insbesondere gemeinsame
                       Stadionbesuche
                       und Veranstaltungen – gefertigt und im Rahmen der &ouml;ffentlichkeitsarbeit
                       ver&ouml;ffentlicht werden d&uuml;rfen. Dies gilt nicht f&uuml;r Minderj&auml;hrige.
                       <br/><br/>
                       Dir ist bewusst, dass die Fotos und Videos zu deiner Person bei der
                       Ver&ouml;ffentlichung
                       im Internet oder in sozialen Netzwerken weltweit abrufbar sind. Eine
                       Weiterverwendung und/oder Ver&auml;nderung durch Dritte kann hierbei nicht
                       ausgeschlossen
                       werden.
                       <br/><br/>
                       Eine vollst&auml;ndige L&ouml;schung der ver&ouml;ffentlichten Fotos und
                       Videoaufzeichnungen im
                       Internet
                       kann durch den Verein nicht sichergestellt werden, da z. B. andere Internetseiten
                       die Fotos
                       kopiert oder ver&auml;ndert haben k&ouml;nnten. Der Verein kann nicht haftbar
                       gemacht werden
                       f&uuml;r Art
                       und Form der Nutzung durch Dritte wie z. B. f&uuml;r das Herunterladen von Fotos und
                       Videos und
                       deren anschlie&szlig;ende Nutzung und Ver&auml;nderung.</p>
                   </div>}
               </li>
               <li style={{width: "91.8%"}}>
                   <FormControlLabel control={
                       <Switch
                           defaultChecked
                           color="primary"
                           id="PublicBirthday"
                           checked={props.data.PublicBirthday}
                           onChange={setNewState}
                           inputProps={{'aria-label': 'An meinem Geburtstag auf der Webseite eine Gru&szlig;karte erhalten'}}
                       />}
                                     label="Ich bin damit einverstanden, da&szlig; an meinem Geburtstag auf der Webseite des Fanclubs eine Gru&szlig;karte ver&ouml;ffentlicht wird"/>
                   {!props.isAdmin && <div className="infoBox"><p>
                       Die Webseite des Fanclubs bietet seinen Mitgliedern an, am Geburtstag des Mitgliedes
                       auf der Webseite eine pers&ouml;nliche Gru&szlig;karte zu schalten. Dir ist bewusst,
                       dass
                       dadurch Teile deiner pers&ouml;nlichen Daten (Name, Vorname, Geburtstag und Alter)
                       &ouml;ffentlich f&uuml;r jedermann bekannt werden k&ouml;nnen. Die Gru&szlig;karte
                       wird dabei nur
                       am Geburtstag des Mitgliedes geschalten.
                       <br/><br/>
                       Dir ist bewusst, dass die von deiner Person in diesem Zusammenhang am Tag der
                       Ver&ouml;ffentlichung (Schaltung der Gru&szlig;karte) beaknnt gewordenen Daten im
                       Internet oder in sozialen Netzwerken weltweit abrufbar sind. Eine Weiterverwendung
                       und/oder Ver&auml;nderung durch Dritte kann hierbei nicht ausgeschlossen werden.</p>
                   </div>}
               </li>
               <li>
                   <TextField className="kontakt-feld"
                              id="BaseChangeDate"
                              label="zu Letzt ge&auml;ndert am"
                              type="date"
                              defaultValue=""
                              InputLabelProps={{
                                  shrink: true,
                              }}
                              fullWidth
                              value={props.data.BaseChangeDate}
                              disabled={true}/>
               </li>
           </ul>
       </div>
    )

}

export default BaseData;
