import React, {useEffect, useState} from "react";
import Carousel from 'react-material-ui-carousel'
import {Paper} from '@material-ui/core'
import {useLocation} from "react-router-dom";



function ImageViewer(props: any) {
    const location = useLocation();
    const [siteName, setSiteName] = useState("");
    const [eventName, setEventName] = useState("");

    let ap = props.autoPlay;
    if (ap === undefined)
        ap = true;

    let id = props.indicators;
    if (id === undefined)
        id = props.items.length < 7 && props.items.length > 1;

    let imagePath = props.path !== undefined ? props.path : "./images/";

    useEffect(() => {
        props.items.forEach((item: any) => {
            const img = new Image();
            img.src = imagePath + item;
        });
        if(imagePath.indexOf('./events/images') > -1) {
            setEventName(imagePath.replace('./events/images/', '').replace('/', ''));
        }else{
            setEventName('');
        }
        setSiteName(location.pathname.replace('/',''));
    }, []);

    return (
        <div style={{maxWidth: props.maxWidth}}>
            <Carousel autoPlay={ap} indicators={id} stopAutoPlayOnHover={true} timeout={1500}>
                {
                    props.items.map((item: any, i: number) => <Item path={imagePath} key={i} item={item} css={props.css}
                                                                    fontSize={props.untertitel}
                                                                    linkTo={props.linkToDescription}/>)
                }
            </Carousel>
            {props.items.length > 1 && siteName != "Bilder" && eventName != "" &&
                <p style={{fontSize: "small"}}>Weitere Bilder findet ihr in der Veranstaltungsübersicht im
                    Bereich <i><a href={"#/Bilder?event=" + eventName}>Bilder</a></i></p>}
        </div>
    )
}

function Item(props: any) {
    let imgUrl = `${props.path}${props.item.src}`;
    // if (!imgUrl.startsWith("./"))
    //     imgUrl = `./images/${imgUrl}`;
    let fs = props.fontSize || "small";
    return (
            props.linkTo !== undefined ?
            <a style={{textDecoration: "none"}}
               href={props.item.description} target="_blank" rel="noreferrer">
                <Paper elevation={3}>
                    <div className={props.className}>
                        <h4>{props.item.name}</h4>
                        <img alt={props.item.name} src={imgUrl}
                             style={{width: '100%', height: '100%', alignItems: "center"}}/>
                        <p style={{fontSize: fs}}>{props.item.description}</p>
                    </div>
                </Paper>
            </a> :
            <Paper elevation={3}>
                <div className={props.className}>
                    <h4>{props.item.name}</h4>
                    <img alt={props.item.name} src={imgUrl}
                         style={{width: '100%', height: '100%', alignItems: "center"}}/>
                    <p style={{fontSize: fs}}>{props.item.description}</p>
                </div>
            </Paper>
    )
}

export default ImageViewer;
