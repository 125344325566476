import {createSlice} from "@reduxjs/toolkit";
import {RegisterEvent, RegisterEventType} from "../app/dto/appData";
import moment from "moment";

export const appSlice = createSlice({
    name: 'appData',
    initialState: {
        NewsletterText: "",
        succeded: false,
        succededText: "",
        failed: false,
        errorText: "",
        openImportDialog: false,
        contactFormular: {
            Name: "",
            City: "",
            Email: "",
            Website: "",
            Subject: "",
            Message: "",
            ValidCaptcha: "",
            Recipient: {
                executive: false,
                memberService: false,
                cardsService: false,
                webMaster: true,
            }
        },
        Event: {
            Current: 0,
            Notes: "",
            LastName: "",
            FirstName: "",
            LoginNumber: "",
            LoginName: "",
            Unkosten: false,
            GemischterSalat: false,
            KartoffelSalat: false,
            NudelSalat: false,
            WurstSalat: false,
            GurkenSalat: false,
            TomatenSalat: false,
            Kuchen: false,
            CountGemischterSalat: 0,
            CountKartoffelSalat: 0,
            CountNudelSalat: 0,
            CountWurstSalat: 0,
            CountGurkenSalat: 0,
            CountTomatenSalat: 0,
            CountKuchen: 0,
            UserId: null,
            EventId: null
        },
        events: new Array<RegisterEvent>(),
        eventTypes: new Array<RegisterEventType>(),
        currentEvent: {
            Id: '-1',
            EventType: "",
            EventDescription: "",
            EventTypeDescription: "",
            EventDate: "",
            RegistrationStart: "",
            RegistrationOpen: "",
            RegistrationEnd: "",
            EventStart: "",
            EventVenue: "",
            ContributionToExpenses: ""
        }
    },
    reducers: {
        setAppData: (state, action) => {
            if (state.hasOwnProperty(action.payload.key)) {
                // @ts-ignore
                state[action.payload.key] = action.payload.value;
            }
        },
        addNewEvent: (state, action) => {
            const existingEvent = state.events.find(event => event.Id === action.payload.Id);
            state.currentEvent = {
                Id: '-1',
                EventType: "",
                EventDescription: "",
                EventTypeDescription: "",
                EventDate: "",
                RegistrationStart: "",
                RegistrationOpen: "",
                RegistrationEnd: "",
                EventStart: "",
                EventVenue: "",
                ContributionToExpenses: ""
            };
            let newItems = [];
            if (existingEvent) {
                state.currentEvent.Id = action.payload.Id.toString();
                state.currentEvent.EventType = action.payload.EventType?? "";
                state.currentEvent.EventDescription = action.payload.EventDescription;
                state.currentEvent.EventTypeDescription = action.payload.EventTypeDescription;
                state.currentEvent.EventDate = action.payload.EventDate?? "";
                state.currentEvent.RegistrationStart = action.payload.RegistrationStart?? "";
                state.currentEvent.RegistrationOpen = action.payload.RegistrationOpen ? '1' : '0';
                state.currentEvent.RegistrationEnd = action.payload.RegistrationEnd?? "";
                state.currentEvent.EventStart = action.payload.EventStart?? "";
                state.currentEvent.EventVenue = action.payload.EventVenue?? "";
                state.currentEvent.ContributionToExpenses = action.payload.ContributionToExpenses?? "";
                newItems = state.events.filter(event => event.Id !== action.payload.Id);
            } else {
                let now = new Date();
                state.currentEvent.Id = action.payload.Id;
                state.currentEvent.EventType = action.payload.EventType;
                state.currentEvent.EventDescription = action.payload.EventDescription;
                state.currentEvent.EventTypeDescription = action.payload.EventTypeDescription;
                state.currentEvent.EventDate = moment(new Date(now.getTime() + 42 * 24 * 60 * 60 * 1000)).format('YYYY-MM-DD');
                state.currentEvent.RegistrationStart = moment(new Date(now.getTime() + 14 * 24 * 60 * 60 * 1000)).format('YYYY-MM-DD');
                state.currentEvent.RegistrationOpen = '1';
                state.currentEvent.RegistrationEnd = moment(new Date(now.getTime() + 28 * 24 * 60 * 60 * 1000)).format('YYYY-MM-DD');
                state.currentEvent.EventStart = '18:30';
                state.currentEvent.EventVenue = '';
                state.currentEvent.ContributionToExpenses = '';
                newItems = state.events;
            }
            newItems.push({
                Id: state.currentEvent.Id,
                EventType: state.currentEvent.EventType,
                EventDescription: state.currentEvent.EventDescription,
                EventTypeDescription: state.currentEvent.EventTypeDescription,
                EventDate: state.currentEvent.EventDate,
                RegistrationStart: state.currentEvent.RegistrationStart,
                RegistrationOpen: state.currentEvent.RegistrationOpen !== '0',
                RegistrationEnd: state.currentEvent.RegistrationEnd,
                EventStart: state.currentEvent.EventStart,
                EventVenue: state.currentEvent.EventVenue,
                ContributionToExpenses: state.currentEvent.ContributionToExpenses,
            });
            newItems.sort((a, b) =>
                {
                    if (a.EventDescription < b.EventDescription) return -1;
                    if (a.EventDescription > b.EventDescription) return 1;
                    return 0;
                }
            );
            state.events = newItems;
        },
        hideSnackbar: (state) => {
            state.succeded = false;
            state.failed = false;
            state.succededText = "";
            state.errorText = "";
        },
        showSuccededSnackbar: (state, action) => {
            state.succeded = true;
            state.failed = false;
            state.succededText = action.payload.message;
            state.errorText = "";
        },
        showFailedSnackbar: (state, action) => {
            state.succeded = false;
            state.failed = true;
            state.succededText = "";
            state.errorText = action.payload.message;
        },
        addContact: (state) => {
            // sendet das Kontaktformular ab.
        },
        getEventByType: (state) => {
            // liest die Veranstaltungen aus.
        },
        getEventData: (state) => {
            // liest die Anzahl der angemeldeten Salate/Kuchen.
        },
        updateEventsSuccess: (state, action) => {
            console.log("Events: " + action.payload)
            state.events = action.payload;
        },
        setEventLoginData: (state, action) => {
            const name = action.payload.key;
            if (state.Event.hasOwnProperty(name)) {
                // @ts-ignore
                state.Event[name] = action.payload.value;
            }
        },

        setEventByType: (state, action) => {
            state.Event.Current = action.payload.eventType;
        },
        setNewsletterText: (state, action) => {
            state.NewsletterText = action.payload.value;
        },
        addUserToEvent: (state, action) => {
            console.log("UserId: " + action.payload.UserId)
            state.Event.UserId = action.payload.UserId;
        },
        setAllEvents: (state, action) => {
            state.events = action.payload.Events;
            state.eventTypes = action.payload.EventTypes;
        },
        setEventId: (state, action) => {
            state.Event.EventId = action.payload.EventId;
        },
        setEventDataCount: (state, action) => {
            state.Event.CountGemischterSalat = action.payload.CountGemischterSalat;
            state.Event.CountKartoffelSalat = action.payload.CountKartoffelSalat;
            state.Event.CountNudelSalat = action.payload.CountNudelSalat;
            state.Event.CountWurstSalat = action.payload.CountWurstSalat;
            state.Event.CountGurkenSalat = action.payload.CountGurkenSalat;
            state.Event.CountTomatenSalat = action.payload.CountTomatenSalat;
            state.Event.CountKuchen = action.payload.CountKuchen;
        },

        registerToEvent: (state) => {
            // meldet den Benutzer beim Event an.
        },
        getAllEvents: (state) => {
            // lest alle definierten Events aus de DB
        },
        updateEvents: (state) => {
            // lest alle definierten Events aus de DB
        },
        sendNewsletterMail: (state) => {
            // sendet Newsletter.
        },
        unregisterToEvent: (state) => {
            // meldet den Benutzer beim Event ab.
        },
        sendNewsletterMailSucceeded: (state, action) => {

        },
        sendNewsletterMailFailed: (state, action) => {

        },

        setContactFormularData: (state, action) => {
            const name = action.payload.key;
            if ((name === "cardsService" ||
                    name === "executive" ||
                    name === "memberService" ||
                    name === "webMaster") &&
                state.contactFormular.Recipient.hasOwnProperty(name)) {
                // @ts-ignore
                state.contactFormular.Recipient[name] = action.payload.value;
            } else if (state.contactFormular.hasOwnProperty(name)) {
                // @ts-ignore
                state.contactFormular[name] = action.payload.value;
            }
        },


    },

})

export const {
    setAppData,
    addContact,
    hideSnackbar,
    showFailedSnackbar,
    showSuccededSnackbar,
    setContactFormularData,
    setEventLoginData,
    setEventByType,
    getEventByType,
    setNewsletterText,
    getEventData,
    addNewEvent,
    registerToEvent,
    sendNewsletterMail,
    unregisterToEvent,
    setEventId,
    addUserToEvent,
    setAllEvents,
    updateEventsSuccess,
    getAllEvents,
    updateEvents,
    setEventDataCount
} = appSlice.actions;

export default appSlice.reducer;
